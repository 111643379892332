import { types, getParent } from "mobx-state-tree"

export const OptionString = types
  .model({
    name: types.string,
    value: types.string
  });

export const OptionNumber = types
  .model({
    name: types.string,
    value: types.number
  });  

const renderOption = (optionData, index) => {
  if (optionData.hasOwnProperty('value') && optionData.hasOwnProperty('name'))
    return (<option value={optionData.value} key={optionData.value + '_' + index}>{optionData.name}</option>);
  else
    return (<option key={optionData + '_' + index}>{optionData}</option>);
}

const renderOptions = options => {
  return options.map((option, index) => renderOption(option, index))
}

const ages = [];

for (let x = 10; x <= 99; x++) {
    ages.push(x.toString());
}

export const OptionsStore = types
  .model("OptionsStore", {
    gender: types.optional(types.array(types.string), [
      'Male',
      'Female',
      'Other',
    ]),
    age: types.optional(types.array(types.string), ages),
    priorTraining: types.optional(types.array(types.string), [
      '0-3 years',
      '3-5 years',
      '5-10 years',
      '10+ years'
    ]),
    currentCardio: types.optional(types.array(types.string), [
      'None',
      '1-2 times weekly',
      '3-5 times weekly',
      'Daily'
    ]),
    yesNo: types.optional(types.array(types.string), ['Yes', 'No']),
    yesNoNumber: types.optional(types.array(OptionNumber), [
      { name: 'Yes', value: 1 },
      { name: 'No', value: 0 },
    ]),
    yesNANumber: types.optional(types.array(OptionNumber), [
      { name: 'Yes', value: 1 },
      { name: 'N/A', value: 0 },
    ]),
    
  })
  .views(self => ({
    get shop() {
      return getParent(self)
    },
  }))
  .actions(self => {
    
    return {
      renderOptions
    }
  });