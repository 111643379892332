import React from 'react';
import { types, flow, getParent, applySnapshot } from "mobx-state-tree"
import { OptionsStore } from "./OptionsStore";
import { AuthStore } from "./AuthStore";
import { ViewStore } from "./ViewStore";
import { ProspectStore } from './ProspectStore';


const RootStore = types
    .model({
      auth: types.optional(AuthStore, {}),
      options: types.optional(OptionsStore, {}),
      view: types.optional(ViewStore, {}),
      prospect: types.optional(ProspectStore, {}),
    })
    .views(self => ({

	/*
      get activeFund() {
        return self.fund.activeFund;
      }
      */
    }))
    .actions(self => {

      async function fetch () {
        const result = await window.fetch.apply(null, arguments);

        if (result.status == 401)
          self.auth.clearAuth();

        return result;
      }

      const afterCreate = flow(function* _afterCreate() {

      })

      return {
          fetch,
          afterCreate,
      }
  });

/* Store helpers */
const StoreContext = React.createContext();
 
export const StoreProvider = ({ children, store }) => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
 
/* Hook to use store in any functional component */
export const useStore = () => React.useContext(StoreContext);
 
/* HOC to inject store to any functional or class component */
export const withStore = (Component) => (props) => {
  return <Component {...props} store={useStore()} />;
};  

export default RootStore;  
