import { observer } from "mobx-react-lite"

const Page404 = observer(props => {

  return (
    <div className="page404">
      Sorry, the page requested was not found
    </div>
  );
})

export default Page404;