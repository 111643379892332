import { observer } from "mobx-react-lite";
import './App.css';
import { useStore } from "./stores/RootStore";
import PageHome from "./components/PageHome";
import Page404 from "./components/Page404";
import PageTest from "./components/PageTest";
import PageFreeGluteWorkout from "./components/PageFreeGluteWorkout";

const App = observer(props => {

  const store = useStore();
  const { authorized } = store.auth;
  const { page } = store.view;

  switch (page) {

    case "home":
      return <PageHome />;

    case "freegluteworkout":
      return <PageFreeGluteWorkout />;    

    case "test":
      return <PageTest />;  

    default:
      return <Page404 />;  
  }

})

export default App;
