import { observer } from "mobx-react-lite";
import React, { useState } from 'react'
import * as EmailValidator from 'email-validator';

const FormStepEmail = observer(props => {

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorFields, setErrorFields] = useState({});

  const handleChangeEmail = e => {
    setEmail(e.target.value);
    setErrorFields({...errorFields, email: false});
  };

  const handleChangeFirstName = e => {
    setFirstName(e.target.value);
    setErrorFields({...errorFields, firstName: false});
  };

  const handleChangeLastName = e => {
    setLastName(e.target.value);
    setErrorFields({...errorFields, lastName: false});
  };

  const handleSubmit = e => {
    const { onSubmit } = props;
    let newErrorFields = {};

    if (firstName.length < 1)
      newErrorFields.firstName = true;

    if (lastName.length < 1)
      newErrorFields.lastName = true;

    if (!EmailValidator.validate(email)) 
      newErrorFields.email = true;

    setErrorFields(newErrorFields);  

    if (Object.entries(newErrorFields).length) {
      setErrorMessage('Please correct the errors below')
      return;
    }

    setErrorMessage('');

    if (onSubmit)
      onSubmit({
        firstName,
        lastName,
        email
      });
  };

  return (
    <div className="formstepfitnessinfo">
      {errorMessage && <div className="errormessage">{errorMessage}</div>}
      <input className={'firstnameinput textinput' + (errorFields.firstName ? ' error' : '')} type="text" onChange={ handleChangeFirstName } value={ firstName } placeholder="First name" />
      <input className={'lastnameinput textinput' + (errorFields.lastName ? ' error' : '')} type="text" onChange={ handleChangeLastName } value={ lastName } placeholder="Last name" />
      <input className={'emailinput textinput' + (errorFields.email ? ' error' : '')} type="email" onChange={ handleChangeEmail } value={ email } placeholder="Email address" />
      <button className="nextbutton buttonpink bouncy" role="button" onClick={ handleSubmit }>NEXT &#x25BA;</button>
    </div>
  );
})

export default FormStepEmail;