import { types, flow, getParent, applySnapshot } from "mobx-state-tree"
import _ from 'lodash'


export const AuthStore = types
  .model({
    authorized: types.optional(types.boolean, false),
    roles: types.optional(types.array(types.string), []),
    userid: types.maybeNull(types.number),
    username: types.maybeNull(types.string),
    firstname: types.maybeNull(types.string),
    lastname: types.maybeNull(types.string),
    activemodelid: types.maybeNull(types.number),
    authError: types.optional(types.boolean, false)
  })
  .views(self => ({
    get store() {
      return getParent(self)
    },
  }))
  .actions(self => {

    function setAuthorized(value) {
      if (value === false)
        self.clearAuth();
      else
        self.authorized = value;
    }

    function setAuthError(value) {
      self.authError = value;
    }

    function clearAuth() {
      applySnapshot(self, {});
    }

    const logout = flow(function* logout() {
      try {
        const result = yield self.store.fetch("/api/getcurrentuser?Logout=true&v=2", {
          method: 'POST'
        });

      } catch (err) {
        console.error("Failed to logout user ", err)
      }
    })

    const authenticate = flow(function* authenticate(username, password) {

      self.authError = false;
      try {
        const formData = new FormData();
        if (typeof username !== undefined)
          formData.append('j_username', username);

        if (typeof password !== undefined)  
          formData.append('j_password', password);

        const result = yield self.store.fetch("/api/getcurrentuser?v=2", {
          method: 'POST',
          body: formData
        });

        if (result.status == 200) {
          const userData = yield result.json();
          applySnapshot(self, { ...userData, ...{ authorized: true } });
        }
        else if (result.status == 401)
        {
        //  self.authError = true;
        }
        else
          throw new Error('Status:' + result.status)

        return result;  

      } catch (err) {
        console.error("Failed to authenticate user ", err)
      }
    })


    
    return {
      authenticate,
      logout,
      clearAuth,
      setAuthorized,
      setAuthError
    }
  });