import { observer } from "mobx-react-lite"

const PageTest = observer(props => {

  return (
    <div className="pagetest">
      This is a test page
    </div>
  );
})

export default PageTest;