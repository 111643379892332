import { observer } from "mobx-react-lite";
import React, { useState } from 'react'
import './PageFreeGluteWorkout.css';
import FormStepEmail from "./FormStepEmail";
import FormStepFitnessInfo from "./FormStepFitnessInfo";
import FormStepGoals from "./FormStepGoals";
import FormStepConsult from "./FormStepConsult";

const PageFreeGluteWorkout = observer(props => {

  const [step, setStep] = useState('email');

  const handleEmailSubmit = data => {
    console.log(data);
    setStep('fitnessinfo');
  }

  const handleFitnessInfoSubmit = data => {
    console.log(data);
    setStep('goals');
  }

  const handleGoalsSubmit = data => {
    console.log(data);
    setStep('consult');
  }

  const handleConsultSubmit = data => {
    console.log(data);
    setStep('thankyou');
  }

  return (
    <div className="pagefreegluteworkout bggrad">
      <img className="logotop" src="images/logoheader.png" />
      <div className="offertitle">
        <img className="offertitleleft pulsate" src="images/text_steel.png" />
        <img className="offertitlepeach" src="images/steelpeach.png" />
        <img className="offertitleright pulsate2" src="images/text_glutes.png" />
      </div>
      <div className="">
        { step === 'email' && <FormStepEmail onSubmit={ handleEmailSubmit } /> }
        { step === 'fitnessinfo' && <FormStepFitnessInfo onSubmit={ handleFitnessInfoSubmit } /> }
        { step === 'goals' && <FormStepGoals onSubmit={ handleGoalsSubmit } /> }
        { step === 'consult' && <FormStepConsult onSubmit={ handleConsultSubmit } /> }
      </div>
    </div>
  );
})

export default PageFreeGluteWorkout;