import { types, flow, getParent, applySnapshot } from "mobx-state-tree"
import _ from 'lodash'


export const ProspectQuestion = types
  .model({
    question: types.maybeNull(types.string),
    response: types.maybeNull(types.string),
  });

export const Prospect = types
  .model({
    id: types.maybeNull(types.identifier),
    firstname: types.maybeNull(types.string),
    lastname: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    age: types.maybeNull(types.number),
    priorTraining: types.maybeNull(types.string),
    currentCardio: types.maybeNull(types.string),
    goals: types.maybeNull(types.string),
    freeConsult: types.maybeNull(types.string)
  })
  .views(self => ({
    /*
    get store() {
      return getParent(self)
    },
    */
  }))
  .actions(self => {

    /*
    const authenticate = flow(function* authenticate(username, password) {

      
      self.authError = false;
      try {
        const formData = new FormData();
        if (typeof username !== undefined)
          formData.append('j_username', username);

        if (typeof password !== undefined)  
          formData.append('j_password', password);

        const result = yield self.store.fetch("/api/getcurrentuser?v=2", {
          method: 'POST',
          body: formData
        });

        if (result.status == 200) {
          const userData = yield result.json();
          applySnapshot(self, { ...userData, ...{ authorized: true } });
        }
        else if (result.status == 401)
        {
        //  self.authError = true;
        }
        else
          throw new Error('Status:' + result.status)

        return result;  

      } catch (err) {
        console.error("Failed to authenticate user ", err)
      }
    })
    */

    return {
    }
  });

export const ProspectStore = types
  .model({
    steelGlutesProspect: types.maybeNull(Prospect),
  })
  .views(self => ({
    get store() {
      return getParent(self)
    },
  }))
  .actions(self => {
    
    return {
    }
  });