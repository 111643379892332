import { observer } from "mobx-react-lite";
import React, { useState } from 'react';
import { useStore } from "../stores/RootStore";

const FormStepFitnessInfo = observer(props => {

  const store = useStore();
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [priorTraining, setPriorTraining] = useState('');
  const [currentCardio, setCurrentCardio] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [errorFields, setErrorFields] = useState({});


  const handleChangeGender = e => {
    setGender(e.target.value);
    setErrorFields({...errorFields, gender: false});
  };

  const handleChangeAge = e => {
    setAge(e.target.value);
    setErrorFields({...errorFields, age: false});
  };

  const handleChangePriorTraining = e => {
    setPriorTraining(e.target.value);
    setErrorFields({...errorFields, priorTraining: false});
  };

  const handleChangeCurrentCardio = e => {
    setCurrentCardio(e.target.value);
    setErrorFields({...errorFields, currentCardio: false});
  };

  const handleSubmit = e => {
    const { onSubmit } = props;
    let newErrorFields = {};

    if (gender.length < 1)
      newErrorFields.gender = true;

    if (age.length < 1)
      newErrorFields.age = true;

    if (priorTraining.length < 1)
      newErrorFields.priorTraining = true;

    setErrorFields(newErrorFields);  

    if (Object.entries(newErrorFields).length) {
      setErrorMessage('Please correct the errors below')
      return;
    }

    setErrorMessage('');

    if (onSubmit)
      onSubmit({
        gender,
        age,
        priorTraining,
        currentCardio
      });
  };

  const { options } = store;

  return (
    <div className="formstepfitnessinfo">
      {errorMessage && <div className="errormessage">{errorMessage}</div>}
      <select className={'genderinput selectinput' + (errorFields.gender ? ' error' : '') + (gender.length ? '' : ' placeholder')}  onChange={handleChangeGender} value={ gender } >
        <option className="placeholder" value={''} disabled>Gender</option>
        { options.renderOptions(options.gender) }
      </select>
      <select className={'ageinput selectinput' + (errorFields.age ? ' error' : '') + (age.length ? '' : ' placeholder')}  onChange={handleChangeAge} value={ age } >
        <option className="placeholder" value={''} disabled>Age</option>
        { options.renderOptions(options.age) }
      </select>
      <select className={'priortraininginput selectinput' + (errorFields.priorTraining ? ' error' : '') + (priorTraining.length ? '' : ' placeholder')}  onChange={handleChangePriorTraining} value={ priorTraining } >
        <option className="placeholder" value={''} disabled>Prior Training</option>
        { options.renderOptions(options.priorTraining) }
      </select>
      <select className={'currentcardioinput selectinput' + (errorFields.currentCardio ? ' error' : '') + (currentCardio.length ? '' : ' placeholder')}  onChange={handleChangeCurrentCardio} value={ currentCardio } >
        <option className="placeholder" value={''} disabled>Current Cardio</option>
        { options.renderOptions(options.currentCardio) }
      </select>
      <button className="nextbutton buttonpink bouncy" role="button" onClick={ handleSubmit }>NEXT &#x25BA;</button>
    </div>
  );
})

export default FormStepFitnessInfo;