import { observer } from "mobx-react-lite";
import React, { useState } from 'react';
import { useStore } from "../stores/RootStore";

const FormStepConsult = observer(props => {

  const store = useStore();

  const [errorMessage, setErrorMessage] = useState('');
  const [errorFields, setErrorFields] = useState({});

  const handleSubmit = e => {
    const { onSubmit } = props;
    let newErrorFields = {};

    setErrorFields(newErrorFields);  

    if (Object.entries(newErrorFields).length) {
      setErrorMessage('Please correct the errors below')
      return;
    }

    setErrorMessage('');

    if (onSubmit)
      onSubmit({
      });
  };

  const { options } = store;

  return (
    <div className="formstepconsult">
      {errorMessage && <div className="errormessage">{errorMessage}</div>}
      <div className="consultQuestion question">
        Would you be interesed in a FREE one-on-one fitness consultation?
      </div>
      <button className="consultyesbutton buttonpink bouncy" role="button" onClick={ handleSubmit }>YES!!!</button><br />
      <button className="consultnobutton buttongray" role="button" onClick={ handleSubmit }>No Thanks</button>
    </div>
  );
})

export default FormStepConsult;