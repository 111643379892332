import React, { createContext, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "mobx-react";
import { observable, reaction } from "mobx";
import './index.css';
import createRouter from "./utils/router"
import App from './App';
import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';
import RootStore, { StoreProvider } from './stores/RootStore';
import { onPatch } from 'mobx-state-tree';

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = RootStore.create();

window.store = store;
onPatch(store, patch => {
  console.log(patch);
})


root.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </React.StrictMode>
);

/**
 * Routing
 */

 reaction(
  () => store.view.currentUrl,
  path => {
      if (window.location.pathname !== path) {
          window.history.pushState(null, null, path)
          ReactGA.send({ hitType: "pageview", page: path });
      }
  }
)

const router = createRouter({
  "/": store.view.openHomePage,
  "/freegluteworkout": store.view.openFreeGluteWorkoutPage,
  "/test": store.view.openTestPage,
  "/404": store.view.open404Page,
})

window.onpopstate = function historyChange(ev) {
  if (ev.type === "popstate")
    router(window.location.pathname, '/404')
}

router(window.location.pathname, '/404')


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
