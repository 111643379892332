import { types, getParent } from "mobx-state-tree"

export const ViewStore = types
    .model({
        page: "home",
    })
    .views(self => ({
        get store() {
            return getParent(self)
        },
        get isLoading() {
            return self.store.isLoading
        },
        get currentUrl() {
            switch (self.page) {
                case "home":
                    return "/";
                case "freegluteworkout":
                    return "/freegluteworkout";
                case "test":
                    return "/test";
                default:
                    return "/404";
            }
        },
    }))
    .actions(self => ({
        openHomePage() {
            self.page = "home";
        },
        openFreeGluteWorkoutPage() {
            self.page = "freegluteworkout";
        },
        openTestPage() {
            self.page = "test";
        },
        open404Page() {
            self.page = "404";
        }
    }))