import { observer } from "mobx-react-lite";
import React, { useState } from 'react';
import { useStore } from "../stores/RootStore";

const FormStepGoals = observer(props => {

  const store = useStore();

  const [goals, setGoals] = useState({
    fatLoss: false,
    gainMuscle: false,
    gainStrength: false,
    nutrition: false,
    bodyRecomp: false
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [errorFields, setErrorFields] = useState({});

  const handleChangeGoal = (e, goal) => {
    const goalChange = {};
    goalChange[goal] = e.target.checked;
    setGoals({...goals, ...goalChange});
    setErrorFields({...errorFields, goals: false});
  };

  const handleSubmit = e => {
    const { onSubmit } = props;
    let newErrorFields = {};

    let noGoals = true;  
    for (const prop in goals) {
      if (goals[prop]) {
        noGoals = false;
        break;
      }
    }

    if (noGoals)
      newErrorFields.goals = true;

    setErrorFields(newErrorFields);  

    if (Object.entries(newErrorFields).length) {
      setErrorMessage('Please correct the errors below')
      return;
    }

    setErrorMessage('');

    if (onSubmit)
      onSubmit({
        goals
      });
  };

  const { options } = store;

  return (
    <div className="formstepgoals">
      {errorMessage && <div className="errormessage">{errorMessage}</div>}
      <div className="goalsQuestion question">
        What are your fitness goals?
      </div>
      <div className={'goalsGroup ' + (errorFields.goals ? ' error' : '')}>
        <input type="checkbox" className={'goalInput checkboxinput ' + (errorFields.goals ? ' error' : '')} name="goalFatLoss" onChange={e => handleChangeGoal(e, 'fatLoss')} />Fat loss<br />
        <input type="checkbox" className={'goalInput checkboxinput ' + (errorFields.goals ? ' error' : '')} name="goalGainMuscle" onChange={e => handleChangeGoal(e, 'gainMuscle')} />Gain muscle<br />
        <input type="checkbox" className={'goalInput checkboxinput ' + (errorFields.goals ? ' error' : '')} name="goalGainStrength" onChange={e => handleChangeGoal(e, 'gainStrength')} />Gain strength<br />
        <input type="checkbox" className={'goalInput checkboxinput ' + (errorFields.goals ? ' error' : '')} name="goalNutrition" onChange={e => handleChangeGoal(e, 'nutrition')} />Nutrition<br />
        <input type="checkbox" className={'goalInput checkboxinput ' + (errorFields.goals ? ' error' : '')} name="goalBodyRecomp" onChange={e => handleChangeGoal(e, 'bodyRecomp')} />Body recomposition<br />
      </div>
      <button className="nextbutton buttonpink bouncy" role="button" onClick={ handleSubmit }>NEXT &#x25BA;</button>
    </div>
  );
})

export default FormStepGoals;