import { observer } from "mobx-react-lite"
import '../css/comingsoon.css'

const PageHome = observer(props => {

  return ([
    <div class="main vertcenter">
      <img class="steelneon flicker" src="images/comingsoon/steel_neon.png" />
      <img class="logo" src="images/comingsoon/logo_sm.png" />
      <img class="steelneon bottom flicker" src=".images/comingsoon/steel_neon.png" />
      <img class="sirenneon flicker" src="images/comingsoon/siren_neon.png" />
    </div>,
    <div class="copyright">&copy; 2023 Steel Siren, Inc.</div>
  ]);
})

export default PageHome;