import route from "path-match"

export default function createRouter(routes) {
    const matchers = Object.keys(routes).map(path => [route()(path), routes[path]])
    return function(path, defaultPath) {
        const retval = matchers.some(([matcher, f]) => {
            const result = matcher(path)
            if (result === false) {
                routes[defaultPath]();
                return false;
            }

            f(result)
            return true
        })

        console.log(retval);

        return retval;
    }
}